import React from "react";
import styles from "../../styles/clients.module.scss";
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/carousel.css";
import { Carousel } from "react-responsive-carousel";

import britishAmericanTobacco from "../../images/logos/britishAmericanTobacco.png";
import caritas from "../../images/logos/caritas.png";
import conforama from "../../images/logos/conforama.png";
import coop from "../../images/logos/coop.png";

import denner from "../../images/logos/denner.png";
import dietrich from "../../images/logos/dietrich.png";
import drBahler from "../../images/logos/drBahler.png";
import dropa from "../../images/logos/dropa.png";

import galexis from "../../images/logos/galexis.png";
import globus from "../../images/logos/globus.png";
import herbaCollection from "../../images/logos/herbaColection.png";
import hodel from "../../images/logos/hodel.png";

import importe from "../../images/logos/importe.png";
import jakob from "../../images/logos/jakob.png";
import klay from "../../images/logos/klay.png";
import kmy from "../../images/logos/kmy.png";

import Landi from "../../images/logos/Landi.png";
import lipo from "../../images/logos/lipo.png";
import loeb from "../../images/logos/loeb.png";
import manor from "../../images/logos/manor.png";

import migros from "../../images/logos/migros.png";
import officeWorld from "../../images/logos/officeWorld.png";
import ottos from "../../images/logos/ottos.png";
import pill from "../../images/logos/pill.png";

import prime_concept from "../../images/logos/prime_concept.png";
import prodega from "../../images/logos/prodega.png";
import promena from "../../images/logos/promena.png";
import restoshop from "../../images/logos/restoshop.png";

import spar from "../../images/logos/spar.png";
import sugro from "../../images/logos/sugro.png";
import swidro from "../../images/logos/swidro.png";
import toppharm from "../../images/logos/toppharm.png";

import valora from "../../images/logos/valora.png";
import verbego from "../../images/logos/verbego.png";
import volg from "../../images/logos/volg.png";
import waser from "../../images/logos/waser.png";

const ClientsDesktop = () => {
  return (
    <div className={styles.container}>
      <h2>
        <FormattedMessage id="clients.title" />
      </h2>
      <Carousel
        autoPlay
        interval={4000}
        stopOnHover
        infiniteLoop
        transitionTime={2000}
      >
        <div className={styles.flex_container}>
          <div>
            <img src={britishAmericanTobacco} alt="britishAmericanTobacco" />
          </div>
          <div>
            <img src={caritas} alt="caritas Logo" />
          </div>
          <div>
            <img src={conforama} alt="conforama Logo " />
          </div>
          <div>
            <img src={coop} alt="coop Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={denner} alt="denner Logo" />
          </div>
          <div>
            <img src={dietrich} alt="dietrich Logo" />
          </div>
          <div>
            <img src={drBahler} alt="drBahler Logo" />
          </div>
          <div>
            <img src={dropa} alt="dropa Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={galexis} alt="galexis Logo" />
          </div>
          <div>
            <img src={globus} alt="globus Logo" />
          </div>
          <div>
            <img src={herbaCollection} alt="herbaCollection Logo" />
          </div>
          <div>
            <img src={hodel} alt="hodel Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={importe} alt="importe Logo" />
          </div>
          <div>
            <img src={jakob} alt="jakob Logo" />
          </div>
          <div>
            <img src={klay} alt="klay Logo" />
          </div>
          <div>
            <img src={kmy} alt="kmy Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={Landi} alt="Landi Logo" />
          </div>
          <div>
            <img src={lipo} alt="lipo Logo" />
          </div>
          <div>
            <img src={loeb} alt="loeb Logo" />
          </div>
          <div>
            <img src={manor} alt="manor Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={migros} alt="migros Logo" />
          </div>
          <div>
            <img src={officeWorld} alt="officeWorld Logo" />
          </div>
          <div>
            <img src={ottos} alt="ottos Logo" />
          </div>
          <div>
            <img src={pill} alt="pill Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={prime_concept} alt="prime_concept Logo" />
          </div>
          <div>
            <img src={prodega} alt="prodega Logo" />
          </div>
          <div>
            <img src={promena} alt="promena Logo" />
          </div>
          <div>
            <img src={restoshop} alt="restoshop Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={spar} alt="spar Logo" />
          </div>
          <div>
            <img src={sugro} alt="sugro Logo" />
          </div>
          <div>
            <img src={swidro} alt="swidro Logo" />
          </div>
          <div>
            <img src={toppharm} alt="toppharm Logo" />
          </div>
        </div>

        <div className={styles.flex_container}>
          <div>
            <img src={valora} alt="valora Logo" />
          </div>
          <div>
            <img src={verbego} alt="verbego Logo" />
          </div>
          <div>
            <img src={volg} alt="volg Logo" />
          </div>
          <div>
            <img src={waser} alt="waser Logo" />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default injectIntl(ClientsDesktop);
